.DashboardPage {
  &Counter {
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 70px;
    }
  }
}
